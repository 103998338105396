<template>
  <div
    class="header-search header-search-extended header-search-visible header-search-no-round"
  >
    <div class="row d-none d-lg-block">
      <div class="col-md-12 extra-links text-dark mb-2">
        <div v-html="headerTopLinks.content"></div>
        <!-- <router-link to="/blog/">{{ $t('blog') }}</router-link>
				<router-link to="/klantenservice/">{{ $t('customer_service') }}</router-link>
				<router-link to="/klantenservice/contact/">{{ $t('contact') }}</router-link> -->
      </div>
    </div>
    <!-- <div v-html="headerContactInfo.content"></div> -->
    <a href="#" class="search-toggle d-none" role="button">
      <i class="icon-search"></i>
    </a>
    <form
      action="#"
      method="get"
      @click.stop="showSearchForm"
      @submit.prevent="submitSearchForm"
    >
      <div class="header-search-wrapper search-wrapper-wide">
        <label for="q" class="sr-only">{{ $t("search") }}</label>
        <input
          type="search"
          class="form-control"
          name="q"
          id="q"
          :placeholder="$t('search_placeholder')"
          required
          v-model="searchTerm"
          @input="searchProducts"
        />
        <button class="btn btn-primary" type="submit">
          <!-- <i class="icon-search"></i> -->
          <i class="fa-solid fa-magnifying-glass"></i>
        </button>
      </div>

      <div class="live-search-list">
        <div
          class="autocomplete-suggestions"
          v-if="
            (recommendedBlogs && recommendedBlogs.length > 0) ||
            (recommendedProducts && recommendedProducts.length > 0) ||
            (recommendedCategories && recommendedCategories.length > 0) ||
            (suggestions && suggestions.length > 0)
          "
          @click="goProductPage"
        >
          <div v-if="suggestions && suggestions.length > 0">
            <ul class="suggestion-searches">
              <li v-for="(item, i) in suggestions" :key="i">
                <router-link :to="'/' + item.word">
                  <p>{{ item.word }}</p>
                </router-link>
              </li>
            </ul>
            <hr />
          </div>
          <div v-if="recommendedProducts && recommendedProducts.length > 0">
            <h6>▾ Aanbevolen producten</h6>
            <router-link
              :to="'/' + product.url_key"
              class="autocomplete-suggestion"
              data-index="0"
              v-for="product in recommendedProducts"
              :key="product.url_key"
            >
              <img
                :src="product.small_image.url"
                alt="Product"
                width="40"
                height="40"
                class="product-image"
              />

              <div
                class="search-name"
                v-html="matchEmphasize(product.name)"
              ></div>
              <span class="search-price">
                <div
                  class="product-price mb-0"
                  v-if="
                    product.price_range.maximum_price.regular_price.value ==
                    product.price_range.maximum_price.final_price.value
                  "
                >
                  ${{
                    product.price_range.maximum_price.regular_price.value.toFixed(
                      2
                    )
                  }}
                </div>
                <template v-else>
                  <div
                    class="product-price mb-0"
                    v-if="product.variants.length == 0"
                  >
                    <span class="new-price"
                      >${{
                        product.price_range.maximum_price.regular_price.value.toFixed(
                          2
                        )
                      }}</span
                    >
                    <span class="old-price"
                      >${{
                        product.price_range.maximum_price.final_price.value.toFixed(
                          2
                        )
                      }}</span
                    >
                  </div>
                  <div class="product-price mb-0" v-else>
                    ${{
                      product.price_range.maximum_price.regular_price.value.toFixed(
                        2
                      )
                    }}
                    - ${{
                      product.price_range.maximum_price.final_price.value.toFixed(
                        2
                      )
                    }}
                  </div>
                </template>
              </span>
            </router-link>
          </div>
          <div v-if="recommendedCategories && recommendedCategories.length > 0">
            <h6 class="mt-2">▾ Categorieën voor uw zoekopdracht:</h6>
            <ul class="suggestion-searches">
              <li v-for="(cat, i) in recommendedCategories" :key="i">
                <router-link :to="'/' + cat.url_path">
                  <img
                    :src="cat.image"
                    width="20"
                    class="d-inline"
                    :alt="cat.name"
                  />
                  <span> {{ cat.name }}</span>
                </router-link>
              </li>
            </ul>
          </div>
          <div v-if="recommendedBlogs && recommendedBlogs.length > 0">
            <h6 class="mt-2">▾ {{ $t("about_search") }}:</h6>
            <div class="row">
              <div
                class="col-md-6"
                v-for="(blog, idx) of recommendedBlogs"
                @click="$router.push('/')"
                :key="'blog' + idx"
              >
                <div class="box">
                  <h6>{{ blog.title }}</h6>
                  <p v-html="blog.content" />
                </div>
              </div>
              <!-- <div class="col-md-6">
								<div class="box">
									<h6>Telefoonscherm reinigen</h6>
									<p>Het smartphonegebruik in Nederland is in 2020 gegroeid naar 93%...</p>
								</div>
							</div> -->
            </div>
          </div>
          <hr />
          <h6 class="mt-2">▾ Producten voor je zoekopdracht:</h6>
          <!-- <ProductTwelve :product="[]" /> -->
        </div>
      </div>
    </form>
  </div>
</template>
<script>
// import { mapGetters } from 'vuex';
// import Repository, { baseUrl } from '../../../../../repositories/repository.js';
// import { homeData } from '@/esf_weert_mobilesupplies/utilities/data';
import { baseUrl } from "../../../../../repositories/repository.js";
import { getProductsBySearchPreview } from "@storefront/core/data-resolver/products";

export default {
  data: function () {
    return {
      searchTerm: "",
      searchlTimer: null,
      suggestions: [],
      recommendedProducts: [],
      recommendedCategories: [],
      recommendedBlogs: [],
      timeouts: [],
      baseUrl: baseUrl,
      closeSignal: {
        products: null,
        categories: null,
        blogs: null,
      },
    };
  },
  computed: {
    // ...mapGetters('demo', ['currentDemo'])
    headerTopLinks() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "header-top-links"
      );
    },
    // headerContactInfo() {
    //     return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
    //         "header-contact-info"
    //     );
    // },
  },
  mounted: function () {
    document
      .querySelector("body")
      .addEventListener("click", this.closeSearchForm);
  },
  methods: {
    searchProducts: function () {
      const controller = new AbortController();
      const { signal } = controller;

      this.closeSignal.products = controller;
      if (this.searchTerm.length > 2) {
        // var searchTerm = this.searchTerm;
        this.timeouts.map((timeout) => {
          window.clearTimeout(timeout);
        });
        this.timeouts.push(
          setTimeout(() => {
            // this.suggestions = homeData.products.splice(0, 3);
            getProductsBySearchPreview(this.searchTerm, signal, 20).then(
              (e) => {
                this.suggestions = e.search_suggestions;
                this.recommendedProducts = e.items;
                this.closeSignal.products = null;
              }
            );
            // 		Repository.get(`${baseUrl}/search`, {
            // 			params: {
            // 				searchTerm: searchTerm,
            // 				demo: this.currentDemo
            // 			}
            // 		})
            // 			.then(response => {
            // 				this.suggestions = response.data.reduce(
            // 					(acc, cur) => {
            // 						let max = 0;
            // 						let min = 99999;
            // 						cur.variants.map(item => {
            // 							if (min > item.price)
            // 								min = item.price;
            // 							if (max < item.price)
            // 								max = item.price;
            // 						}, []);

            // 						if (cur.variants.length == 0) {
            // 							min = cur.sale_price
            // 								? cur.sale_price
            // 								: cur.price;
            // 							max = cur.price;
            // 						}
            // 						return [
            // 							...acc,
            // 							{
            // 								...cur,
            // 								minPrice: min,
            // 								maxPrice: max
            // 							}
            // 						];
            // 					},
            // 					[]
            // 				);
            // 			})
            // 			.catch(error => { console.log(error); });
          }, 500)
        );
      } else {
        this.timeouts.map((timeout) => {
          window.clearTimeout(timeout);
        });
        this.suggestions = [];
        this.recommendedProducts = [];
        this.closeSearchForm();
      }
    },
    async getSearchPreviewCategories(searchText) {
      const controller = new AbortController();
      const { signal } = controller;

      this.closeSignal.categories = controller;
      const output = await this.$store.dispatch(
        "productSerie/getCategoriesBySearchPreview",
        { filter: searchText, signal }
      );

      this.closeSignal.categories = null;
      // output.items.forEach((item, index, object) => {
      //   if (item.level == 2) {
      //     object.splice(index, 1);
      //   }
      // });
      this.recommendedCategories = output.items;
    },
    async getSearchPreviewBlogs(searchText) {
      const controller = new AbortController();
      const { signal } = controller;

      this.closeSignal.blogs = controller;
      const output = await this.$store.dispatch(
        "productSerie/getBlogsBySearchPreview",
        { filter: searchText, signal }
      );
      this.closeSignal.blogs = null;
      this.recommendedBlogs = output.items;
    },
    abortAllSignals() {
      if (this.closeSignal.products !== null) {
        this.closeSignal.products.abort();
      }

      if (this.closeSignal.categories !== null) {
        this.closeSignal.categories.abort();
      }
      if (this.closeSignal.blogs !== null) {
        this.closeSignal.blogs.abort();
      }
    },
    matchEmphasize: function (name) {
      var regExp = new RegExp(this.searchTerm, "i");
      return name.replace(regExp, (match) => "<strong>" + match + "</strong>");
    },
    goProductPage: function () {
      this.searchTerm = "";
      this.suggestions = [];
    },
    // searchToggle: function (e) {
    // 	document.querySelector('.header-search').classList.toggle('show');
    // 	document.querySelector('.live-search-list').classList.toggle('d-none');
    // 	e.stopPropagation();
    // },
    showSearchForm: function () {
      document.querySelector(".header .header-search").classList.add("show");
      document
        .querySelector(".header .header-search .live-search-list")
        .classList.remove("d-none");
    },
    closeSearchForm: function (e) {
      console.log(e);
      document.querySelector(".header .header-search").classList.remove("show");
      document
        .querySelector(".header .header-search .live-search-list")
        .classList.add("d-none");
    },
    submitSearchForm: function () {
      if (this.searchTerm != "") {
        clearTimeout(this.searchlTimer);
        this.abortAllSignals();
        this.closeSearchForm();
        this.$router.push("/search/?q=" + this.searchTerm);
        this.searchTerm = "";
      }
      // console.log(e);
      // this.closeSearchForm();
      // this.searchTerm = '';
      // this.$router.push({
      // 	path: '/shop',
      // 	query: {
      // 		searchTerm: this.searchTerm
      // 	}
      // });
    },
  },
  watch: {
    searchTerm() {
      if (this.searchTerm.length > 2) {
        clearTimeout(this.searchlTimer);

        this.searchlTimer = setTimeout(() => {
          this.abortAllSignals();
          this.showSearchForm();
          this.searchProducts();
          this.getSearchPreviewCategories(this.searchTerm);
          this.getSearchPreviewBlogs(this.searchTerm);
        }, 300);
      } else {
        this.closeSearchForm();
      }
    },
  },
};
</script>