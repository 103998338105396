<template lang="html">
    <div>
        <div class="page-wrapper">
            <!-- <language-switch></language-switch> -->
            <header-default></header-default>
            <router-view />
            <footer-default></footer-default>
            <button id="support" ref="scrollTop" title="Support">
                <div class="content">
                    <img src="@/esf_weert_mobilesupplies/assets/static/images/call-agent.png" alt="agent" />
                    <div>
                        <p class="text-dark">{{ $t("support") }}</p>
                        <p class="text-primary">Mobile Supplies</p>
                    </div>
                </div>
                <!-- <i class="icon-arrow-up"></i> -->
            </button>
            <button id="scroll-top" ref="scrollTop" title="Back to Top" @click.prevent="scrollTop">
                <i class="icon-arrow-up"></i>
            </button>
            <router-link to="/compare">
                <button class="btn btn-success" id="compare-float-button" v-if="compareProducts.length > 0">
                    Vergilijk {{ compareProducts.length }} product(en)
                </button>
            </router-link>
            <cookies></cookies>
        </div>
        <div class="mobile-menu-overlay" @click="hideMobileMenu"></div>
        <mobile-menu></mobile-menu>
    </div>
</template>

<script>
// import LanguageSwitch from '@/esf_weert_mobilesupplies/core/components/elements/LanguageSwitch';
import Cookies from '@/esf_weert_mobilesupplies/core/components/elements/Cookies';
import HeaderDefault from '@/esf_weert_mobilesupplies/core/components/partial/headers/HeaderDefault';
import FooterDefault from '@/esf_weert_mobilesupplies/core/components/partial/footers/FooterDefault';
import { isSafariBrowser, isEdgeBrowser } from '@/esf_weert_mobilesupplies/utilities/common';

export default {
    components: {
        // LanguageSwitch,
        Cookies,
        HeaderDefault,
        FooterDefault,
        MobileMenu: () => import('@/esf_weert_mobilesupplies/core/components/partial/home/MobileMenu')
    },
    computed: {
        compareProducts() {
            return this.$store.getters["product/getProductCompare"]
        }
    },
    mounted: function () {
        let scrollTop = this.$refs.scrollTop;
        document.addEventListener(
            'scroll',
            function () {
                if (window.pageYOffset >= 400) {
                    scrollTop.classList.add('show');
                } else {
                    scrollTop.classList.remove('show');
                }
            },
            false
        );

        // setTimeout(() => {
        //     let cc = document.getElementById('cookies')
        //     cc.classList.add('show');
        // }, 2000)
    },
    methods: {
        scrollTop: function () {
            if (isSafariBrowser() || isEdgeBrowser()) {
                let pos = window.pageYOffset;
                let timerId = setInterval(() => {
                    if (pos <= 0) clearInterval(timerId);
                    window.scrollBy(0, -120);
                    pos -= 120;
                }, 1);
            } else {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        },
        hideMobileMenu: function () {
            document.querySelector('body').classList.remove('mmenu-active');
        }
    }
};
</script>