<template>
  <div
    class="dropdown cart-dropdown"
    :class="{ 'show-dropdown': isDropdownVisible }"
    ref="cartDropdown"
  >
    <span class="dropdown-toggle" @click="toggleDropdown">
      <span class="cart-count">{{ cartQuantity }}</span>
      <img
        src="@/esf_weert_mobilesupplies/assets/static/images/icons/cart.png"
        width="25"
        alt="cart"
      />
      <p class="cart-name d-none d-md-block text-capitalize">
        {{ $t("shopping_cart") }}
      </p>
    </span>

    <div
      class="dropdown-menu dropdown-menu-right"
      v-if="cartItems.length > 0"
      key="hasCart"
    >
      <div class="cart-header">
        <p>{{ $t("shopping_cart") }}</p>
        <span class="close" @click="toggleDropdown">x</span>
      </div>
      <p>{{ cartQuantity }} Artikel (en)</p>
      <hr class="m-0 my-2" />
      <div class="dropdown-cart-products">
        <div class="product" v-for="(cart, index) in cartItems" :key="index">
          <div class="product-cart-details">
            <h4 class="product-title" style="height: auto">
              {{ cart.product.name }}
            </h4>
          </div>
          <div
            :style="{
              display: 'flex',
              justifyContent: 'space-between',
              width: '50%',
            }"
          >
            <quantity-input
              :product="cart"
              @change-qty="changeQuantity"
            ></quantity-input>

            <p class="cart-product-price">
              {{
                formatCurrency(
                  cart.product.price_range.maximum_price.regular_price.value
                    ? cart.product.price_range.maximum_price.regular_price.value.toFixed(
                        2
                      )
                    : cart.product.price_range.maximum_price.final_price.value.toFixed(
                        2
                      )
                )
              }}
            </p>

            <button
              class="btn-remove"
              title="Remove Product"
              @click.prevent="
                removeProduct(cart.id);
                $bvModal.show('product-delete');
              "
            >
              <font-awesome-icon
                class="text-primary"
                icon="fa-solid fa-trash"
              />
            </button>
          </div>
        </div>
      </div>
      <div class="dropdown-cart-total mb-0">
        <span>{{ $t("subtotal_cart_title") }}: </span>
        <span class="cart-total-price"
          >€{{ cartPrices.subtotal_including_tax.value }}</span
        >
      </div>
      <div class="dropdown-cart-total pt-0 mb-0">
        <span>{{ $t("shipping_costs") }}: </span>
        <span class="cart-total-price">€{{ shippingCost }}</span>
      </div>
      <hr class="m-0 my-2" />
      <div class="dropdown-cart-total">
        <span>{{ $t("total1") }}: </span>
        <span class="cart-total-price"
          >€{{ cartPrices.grand_total.value }}</span
        >
      </div>

      <div class="dropdown-cart-action">
        <router-link to="/shopping-cart/" class="text-primary">
          <!-- <i class="icon-long-arrow-left text-primary"></i> -->
          <font-awesome-icon
            class="text-primary mr-2"
            icon="fa-solid fa-angle-left"
          />
          {{ $t("goto_cart") }}
        </router-link>
        <router-link
          to="/order/"
          class="btn btn-success"
          :style="{
            color: 'white',
          }"
          >Bestellen</router-link
        >
      </div>
    </div>
    <div class="dropdown-menu dropdown-menu-right" v-else key="noCart">
      <p class="text-center">{{ $t("no_products_in_the_cart") }}</p>
    </div>

    <!-- remove product in cart modal -->
    <b-modal
      id="product-delete"
      class="product-modal-inner"
      centered
      hide-footer
      content-class="defaul-modal"
      size="xl"
    >
      <template #modal-title>Remove Product</template>
      <div class="modal-text d-block border-bottom text-center">
        <p>Are you sure to remove this product?</p>
      </div>
      <div class="modal-btn-box pt-2">
        <b-button
          class="btn-success"
          block
          @click="
            $bvModal.hide('product-delete');
            removeItem(productID);
          "
          >Ok</b-button
        >
        <b-button
          class="btn-success btn-outline"
          block
          @click="$bvModal.hide('product-delete')"
          >{{ $t("cancel") }}</b-button
        >
      </div>
    </b-modal>
    <!-- remove product in cart modal -->
  </div>
</template>

// <script>
// // import { mapGetters, mapActions } from 'vuex-persistedstate';
// import Cart from "@storefront/core/modules/cart/mixins";
// import QuantityInput from '@/esf_weert_mobilesupplies/core/components/elements/QuantityInput.vue';

// export default {
// 	data: function () {
// 		return {
// 			productID: "",
// 			verzendkosten: 4.95,
// 			isDropdownVisible: false,
// 		};
// 	},
// 	components: {
// 		QuantityInput
// 	},
// 	mixins: [Cart],
// 	computed: {
// 		cartItems() {
// 			return this.$store.getters['cart/getCartItems'];
// 		},
// 		cartQuantity() {
// 			return this.$store.getters['cart/getItemsTotalQuantity'];
// 		},
// 		cartPrices() {
// 			return this.$store.getters['cart/getCartPrices'];
// 		},
// 		shippingCost() {
// 			return this.$store.getters['cart/getFreeShippingAmount'];
// 		}
// 	},
// 	methods: {
// 		changeQuantity(qty, id) {
// 			if (qty > 0) {
// 				this.$store.dispatch("cart/updateCartItem", {
// 					id: id,
// 					quantity: qty,
// 				});
// 			} else {
// 				this.$store.dispatch("cart/updateCartItem", {
// 					id: id,
// 					quantity: 1,
// 				});
// 			}
// 			this.updateCrossSell();
// 		},
// 		formatCurrency(amount) {
// 			return this.$helpers.formatCurrency(amount);
// 		},
// 		updateCrossSell() {
// 			let skuList = [];
// 			this.cartItems.forEach((cartItem) => {
// 				if (cartItem.product && cartItem.product.sku) {
// 					skuList.push(cartItem.product.sku);
// 				}
// 			});
// 			this.$store.dispatch("product/loadCrossSellForCart", { skus: skuList });
// 		},
// 		removeProduct(data) {
// 			this.productID = data;
// 			this.updateCrossSell();
// 		},
// 		toggleDropdown() {
// 			this.isDropdownVisible = !this.isDropdownVisible;
// 		},
// 	}
// };
//
</script>


<script>
// import { mapGetters, mapActions } from 'vuex-persistedstate';
import Cart from "@storefront/core/modules/cart/mixins";
import QuantityInput from "@/esf_weert_mobilesupplies/core/components/elements/QuantityInput.vue";

export default {
  data: function () {
    return {
      productID: "",
      verzendkosten: 4.95,
      isDropdownVisible: false,
    };
  },
  components: {
    QuantityInput,
  },
  mixins: [Cart],
  computed: {
    cartItems() {
      return this.$store.getters["cart/getCartItems"];
    },
    cartQuantity() {
      return this.$store.getters["cart/getItemsTotalQuantity"];
    },
    cartPrices() {
      return this.$store.getters["cart/getCartPrices"];
    },
    shippingCost() {
      return this.$store.getters["cart/getFreeShippingAmount"];
    },
  },
  methods: {
    changeQuantity(qty, id) {
      if (qty > 0) {
        this.$store.dispatch("cart/updateCartItem", {
          id: id,
          quantity: qty,
        });
      } else {
        this.$store.dispatch("cart/updateCartItem", {
          id: id,
          quantity: 1,
        });
      }
      this.updateCrossSell();
    },
    formatCurrency(amount) {
      return this.$helpers.formatCurrency(amount);
    },
    updateCrossSell() {
      let skuList = [];
      this.cartItems.forEach((cartItem) => {
        if (cartItem.product && cartItem.product.sku) {
          skuList.push(cartItem.product.sku);
        }
      });
      this.$store.dispatch("product/loadCrossSellForCart", { skus: skuList });
    },
    removeProduct(data) {
      this.productID = data;
      this.updateCrossSell();
    },
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    closeDropdownOnClickOutside(event) {
      const cartDropdown = this.$refs.cartDropdown;
      // Check if the click happened outside the dropdown
      if (
        this.isDropdownVisible &&
        cartDropdown &&
        !cartDropdown.contains(event.target)
      ) {
        this.isDropdownVisible = false;
      }
    },
  },
  mounted() {
    // Add event listener when the component is mounted
    document.addEventListener("click", this.closeDropdownOnClickOutside);
  },
  beforeDestroy() {
    // Remove event listener when the component is destroyed
    document.removeEventListener("click", this.closeDropdownOnClickOutside);
  },
};
</script>