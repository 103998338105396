<template>
	<div>
		<div class="container-fluid service">
			<div class="container">
				<div class="row">
					<div class="col-sm-6 col-lg-3">
						<div class="icon-box icon-box-side">
							<span class="icon-box-icon">
								<i class="icon-star"></i>
							</span>

							<div class="icon-box-content">
								<h3 class="icon-box-title">{{ $t('15_years_of_experience') }}</h3>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae nisi
									scelerisque
									justo
									lacinia sodales</p>
								<router-link to="">{{ $t('read_more_here') }}</router-link>
							</div>
						</div>
					</div>

					<div class="col-sm-6 col-lg-3">
						<div class="icon-box icon-box-side">
							<span class="icon-box-icon">
								<i class="icon-truck"></i>
							</span>

							<div class="icon-box-content">
								<h3 class="icon-box-title">{{ $t('free_shipping_NL') }}</h3>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae nisi
									scelerisque
									justo
									lacinia sodales</p>
								<router-link to="">{{ $t('read_more_here') }}</router-link>
							</div>
						</div>
					</div>

					<div class="col-sm-6 col-lg-3">
						<div class="icon-box icon-box-side">
							<span class="icon-box-icon">
								<i class="icon-tags"></i>
							</span>

							<div class="icon-box-content">
								<h3 class="icon-box-title">{{ $t('more_than_850,000_customers') }}</h3>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae nisi
									scelerisque
									justo
									lacinia sodales</p>
								<router-link to="">{{ $t('read_more_here') }}</router-link>
							</div>
						</div>
					</div>

					<div class="col-sm-6 col-lg-3">
						<div class="icon-box icon-box-side">
							<span class="icon-box-icon">
								<i class="icon-life-ring"></i>
							</span>

							<div class="icon-box-content">
								<h3 class="icon-box-title">{{ $t('pay_afterwards') }}</h3>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae nisi
									scelerisque
									justo
									lacinia sodales</p>
								<router-link to="">{{ $t('read_more_here') }}</router-link>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>

		<div class="newsletter">
			<div class="container">
				<div class="subscribe text-center">
					<!-- <div class="intro"> -->
					<!-- <h4 class="content">Learn about new offers and get more deals by joining our newsletter</h4> -->
					<!-- </div> -->
					<div class="subscribe action d-md-flex d-sm-flex-none" >
						<h3 class="title">{{ $t('alenmelden_newsletter') }}</h3>
						<form action="#">
							<div class="input-group">
								<input type="email" :placeholder="$t('email_address_login_form')"
									aria-label=" Email Adress" required="">
								<div class="input-group-append">
									<button class="btn btn-subscribe" type="submit"><span>{{ $t('subscribe_newsletter')
											}}</span></button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>

		<footer class="footer">
			<div class="footer-middle">
				<div class="container">
					<div class="row g-5">
						<div class="col-sm-6 col-lg-4">
							<!-- <div class="widget widget-about" v-html="mobileFooterLinks.content" /> -->
							<div class="widget widget-about">
								<h4 class="widget-title">Contact</h4>
								<div class="row widget-list">
									<div class="col-md-6">
										<img src="@/esf_weert_mobilesupplies/assets/static/images/logo-footer.png"
											class="footer-logo bg-transparent" alt="Footer Logo" width="200"
											height="25">
									</div>
									<div class="col-md-6">
										<router-link to="">
											Compay BV Straat 3161234 AB Staat info@example.nl
										</router-link>
									</div>
								</div>


								<!-- <p>Praesent dapibus, neque id cursus ucibus, tortor neque egestas augue, eu vulputate magna eros eu erat. </p>

							<div class="social-icons">
								<a
									href="#"
									class="social-icon"
									title="Facebook"
									target="_blank"
								><i class="icon-facebook-f"></i></a>
								<a
									href="#"
									class="social-icon"
									title="Twitter"
									target="_blank"
								><i class="icon-twitter"></i></a>
								<a
									href="#"
									class="social-icon"
									title="Instagram"
									target="_blank"
								><i class="icon-instagram"></i></a>
								<a
									href="#"
									class="social-icon"
									title="Youtube"
									target="_blank"
								><i class="icon-youtube"></i></a>
								<a
									href="#"
									class="social-icon"
									title="Pinterest"
									target="_blank"
								><i class="icon-pinterest"></i></a>
							</div> -->
							</div>
						</div>

						<div class="col-sm-6 col-lg-4">
							<div class="widget">
								<h4 class="widget-title">{{ $t('general_information') }}</h4>

								<div class="row widget-list">
									<div class="col-6">
										<router-link to="">About Mobile Supplies</router-link>
									</div>
									<div class="col-6">
										<router-link to="">How to shop on Mobile Supplies</router-link>
									</div>
									<div class="col-6">
										<router-link to="">FAQ</router-link>
									</div>
									<div class="col-6">
										<router-link to="/klantenservice/contact/">Contact us</router-link>
									</div>
									<div class="col-6">
										<router-link to="/login/">Log in</router-link>
									</div>
								</div>
							</div>
						</div>

						<div class="col-sm-6 col-lg-4">
							<div class="widget">
								<h4 class="widget-title">{{ $t('marks') }}</h4>

								<div class="row widget-list">
									<div class="col-6"><a href="#">Payment Methods</a></div>
									<div class="col-6"><a href="#">Money-back guarantee!</a></div>
									<div class="col-6"><a href="#">Returns</a></div>
									<div class="col-6"><a href="#">Shipping</a></div>
									<div class="col-6"><a href="#">Terms and conditions</a></div>
									<div class="col-6"><a><router-link to="/privacy-policy">Privacy
												Policy</router-link></a></div>
								</div>
							</div>
						</div>

						<div class="col-md-12">
							<div class="widget">
								<h4 class="widget-title">{{ $t('products') }}</h4>

								<div class="row widget-list">
									<div class="col-md-2 col-4">
										<router-link to="">Sign In</router-link>
									</div>
									<div class="col-md-2 col-4">
										<router-link to="/shopping-cart/">View Cart</router-link>
									</div>
									<div class="col-md-2 col-4">
										<router-link to="">My Wishlist</router-link>
									</div>
									<div class="col-md-2 col-4">
										<router-link to="">Track My Order</router-link>
									</div>
									<div class="col-md-2 col-4">
										<router-link to="">Help</router-link>
									</div>
									<div class="col-md-2 col-4">
										<router-link to="/merken/">{{ $t('brands') }}</router-link>
									</div>
									<div class="col-md-2 col-4">
										<a href="#">category</a>
									</div>
									<div class="col-md-2 col-4">
										<a href="#">category</a>
									</div>
									<div class="col-md-2 col-4">
										<a href="#">category</a>
									</div>
									<div class="col-md-2 col-4">
										<a href="#">category</a>
									</div>
									<div class="col-md-2 col-4">
										<a href="#">category</a>
									</div>
									<div class="col-md-2 col-4">
										<a href="#">category</a>
									</div>
									<div class="col-md-2 col-4">
										<a href="#">category</a>
									</div>
									<div class="col-md-2 col-4">
										<a href="#">category</a>
									</div>
									<div class="col-md-2 col-4">
										<a href="#">category</a>
									</div>
									<div class="col-md-2 col-4">
										<a href="#">category</a>
									</div>
									<div class="col-md-2 col-4">
										<a href="#">category</a>
									</div>
									<div class="col-md-2 col-4">
										<a href="#">category</a>
									</div>
									<div class="col-md-2 col-4">
										<a href="#">category</a>
									</div>
									<div class="col-md-2 col-4">
										<a href="#">category</a>
									</div>
									<div class="col-md-2 col-4">
										<a href="#">category</a>
									</div>
									<div class="col-md-2 col-4">
										<a href="#">category</a>
									</div>
									<div class="col-md-2 col-4">
										<a href="#">category</a>
									</div>
									<div class="col-md-2 col-4">
										<a href="#">category</a>
									</div>
								</div>
							</div>
						</div>
						<!-- <div class="col-md-12">
							<div v-html="bottomInfo.content" />
						</div> -->
						<div class="col-md-12 d-flex justify-content-center">
							<img src="@/esf_weert_mobilesupplies/assets/static/images/payments.png"
								class="bg-transparent" alt="Payment methods" width="350" height="20">
						</div>
					</div>
				</div>
			</div>
			<div class="footer-bottom">
				<p class="footer-copyright">{{ $t('prices_include_vat') }}</p>
				<p class="footer-copyright">{{ $t('privacy_copyright', { year: currentYear }) }}</p>
				<p class="footer-copyright">{{ $t('footer_trademark') }}</p>
			</div><!-- End .footer-bottom -->
			<div class="mb-10" v-if="bottomSticky"></div>
		</footer><!-- End .footer -->
	</div>
</template>

<script>
export default {
	data: function () {
		return {
			bottomSticky: false,
			currentYear: new Date().getFullYear(),
		};
	},
	computed: {
		isFullwidth: function () {
			return this.$route.path.includes('fullwidth');
		},
		// mobileSeoFooterlinks() {
		// 	return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
		// 		"mobile_seo_footerlinks"
		// 	);
		// },
		// mobileFooterLinks() {
		// 	return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
		// 		"mobile_footerlinks"
		// 	);
		// },
		// bottomInfo() {
		// 	return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
		// 		"bottom_info"
		// 	);
		// },
	},
	watch: {
		$route: function () {
			this.handleBottomSticky();
		}
	},
	mounted: function () {
		this.handleBottomSticky();
		window.addEventListener('resize', this.handleBottomSticky, {
			passive: true
		});
	},
	unmounted: function () {
		window.removeEventListener('resize', this.handleBottomSticky);
	},
	methods: {
		handleBottomSticky: function () {
			this.bottomSticky =
				this.$route.path.includes('/product/default') &&
				window.innerWidth > 991;
		}
	}
};
</script>