var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"cartDropdown",staticClass:"dropdown cart-dropdown",class:{ 'show-dropdown': _vm.isDropdownVisible }},[_c('span',{staticClass:"dropdown-toggle",on:{"click":_vm.toggleDropdown}},[_c('span',{staticClass:"cart-count"},[_vm._v(_vm._s(_vm.cartQuantity))]),_c('img',{attrs:{"src":require("@/esf_weert_mobilesupplies/assets/static/images/icons/cart.png"),"width":"25","alt":"cart"}}),_c('p',{staticClass:"cart-name d-none d-md-block text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t("shopping_cart"))+" ")])]),(_vm.cartItems.length > 0)?_c('div',{key:"hasCart",staticClass:"dropdown-menu dropdown-menu-right"},[_c('div',{staticClass:"cart-header"},[_c('p',[_vm._v(_vm._s(_vm.$t("shopping_cart")))]),_c('span',{staticClass:"close",on:{"click":_vm.toggleDropdown}},[_vm._v("x")])]),_c('p',[_vm._v(_vm._s(_vm.cartQuantity)+" Artikel (en)")]),_c('hr',{staticClass:"m-0 my-2"}),_c('div',{staticClass:"dropdown-cart-products"},_vm._l((_vm.cartItems),function(cart,index){return _c('div',{key:index,staticClass:"product"},[_c('div',{staticClass:"product-cart-details"},[_c('h4',{staticClass:"product-title",staticStyle:{"height":"auto"}},[_vm._v(" "+_vm._s(cart.product.name)+" ")])]),_c('div',{style:({
            display: 'flex',
            justifyContent: 'space-between',
            width: '50%',
          })},[_c('quantity-input',{attrs:{"product":cart},on:{"change-qty":_vm.changeQuantity}}),_c('p',{staticClass:"cart-product-price"},[_vm._v(" "+_vm._s(_vm.formatCurrency( cart.product.price_range.maximum_price.regular_price.value ? cart.product.price_range.maximum_price.regular_price.value.toFixed( 2 ) : cart.product.price_range.maximum_price.final_price.value.toFixed( 2 ) ))+" ")]),_c('button',{staticClass:"btn-remove",attrs:{"title":"Remove Product"},on:{"click":function($event){$event.preventDefault();_vm.removeProduct(cart.id);
              _vm.$bvModal.show('product-delete');}}},[_c('font-awesome-icon',{staticClass:"text-primary",attrs:{"icon":"fa-solid fa-trash"}})],1)],1)])}),0),_c('div',{staticClass:"dropdown-cart-total mb-0"},[_c('span',[_vm._v(_vm._s(_vm.$t("subtotal_cart_title"))+": ")]),_c('span',{staticClass:"cart-total-price"},[_vm._v("€"+_vm._s(_vm.cartPrices.subtotal_including_tax.value))])]),_c('div',{staticClass:"dropdown-cart-total pt-0 mb-0"},[_c('span',[_vm._v(_vm._s(_vm.$t("shipping_costs"))+": ")]),_c('span',{staticClass:"cart-total-price"},[_vm._v("€"+_vm._s(_vm.shippingCost))])]),_c('hr',{staticClass:"m-0 my-2"}),_c('div',{staticClass:"dropdown-cart-total"},[_c('span',[_vm._v(_vm._s(_vm.$t("total1"))+": ")]),_c('span',{staticClass:"cart-total-price"},[_vm._v("€"+_vm._s(_vm.cartPrices.grand_total.value))])]),_c('div',{staticClass:"dropdown-cart-action"},[_c('router-link',{staticClass:"text-primary",attrs:{"to":"/shopping-cart/"}},[_c('font-awesome-icon',{staticClass:"text-primary mr-2",attrs:{"icon":"fa-solid fa-angle-left"}}),_vm._v(" "+_vm._s(_vm.$t("goto_cart"))+" ")],1),_c('router-link',{staticClass:"btn btn-success",style:({
          color: 'white',
        }),attrs:{"to":"/order/"}},[_vm._v("Bestellen")])],1)]):_c('div',{key:"noCart",staticClass:"dropdown-menu dropdown-menu-right"},[_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("no_products_in_the_cart")))])]),_c('b-modal',{staticClass:"product-modal-inner",attrs:{"id":"product-delete","centered":"","hide-footer":"","content-class":"defaul-modal","size":"xl"},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v("Remove Product")]},proxy:true}])},[_c('div',{staticClass:"modal-text d-block border-bottom text-center"},[_c('p',[_vm._v("Are you sure to remove this product?")])]),_c('div',{staticClass:"modal-btn-box pt-2"},[_c('b-button',{staticClass:"btn-success",attrs:{"block":""},on:{"click":function($event){_vm.$bvModal.hide('product-delete');
          _vm.removeItem(_vm.productID);}}},[_vm._v("Ok")]),_c('b-button',{staticClass:"btn-success btn-outline",attrs:{"block":""},on:{"click":function($event){return _vm.$bvModal.hide('product-delete')}}},[_vm._v(_vm._s(_vm.$t("cancel")))])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }