var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-search header-search-extended header-search-visible header-search-no-round"},[_c('div',{staticClass:"row d-none d-lg-block"},[_c('div',{staticClass:"col-md-12 extra-links text-dark mb-2"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.headerTopLinks.content)}})])]),_vm._m(0),_c('form',{attrs:{"action":"#","method":"get"},on:{"click":function($event){$event.stopPropagation();return _vm.showSearchForm($event)},"submit":function($event){$event.preventDefault();return _vm.submitSearchForm($event)}}},[_c('div',{staticClass:"header-search-wrapper search-wrapper-wide"},[_c('label',{staticClass:"sr-only",attrs:{"for":"q"}},[_vm._v(_vm._s(_vm.$t("search")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchTerm),expression:"searchTerm"}],staticClass:"form-control",attrs:{"type":"search","name":"q","id":"q","placeholder":_vm.$t('search_placeholder'),"required":""},domProps:{"value":(_vm.searchTerm)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.searchTerm=$event.target.value},_vm.searchProducts]}}),_vm._m(1)]),_c('div',{staticClass:"live-search-list"},[(
            (_vm.recommendedBlogs && _vm.recommendedBlogs.length > 0) ||
            (_vm.recommendedProducts && _vm.recommendedProducts.length > 0) ||
            (_vm.recommendedCategories && _vm.recommendedCategories.length > 0) ||
            (_vm.suggestions && _vm.suggestions.length > 0)
          )?_c('div',{staticClass:"autocomplete-suggestions",on:{"click":_vm.goProductPage}},[(_vm.suggestions && _vm.suggestions.length > 0)?_c('div',[_c('ul',{staticClass:"suggestion-searches"},_vm._l((_vm.suggestions),function(item,i){return _c('li',{key:i},[_c('router-link',{attrs:{"to":'/' + item.word}},[_c('p',[_vm._v(_vm._s(item.word))])])],1)}),0),_c('hr')]):_vm._e(),(_vm.recommendedProducts && _vm.recommendedProducts.length > 0)?_c('div',[_c('h6',[_vm._v("▾ Aanbevolen producten")]),_vm._l((_vm.recommendedProducts),function(product){return _c('router-link',{key:product.url_key,staticClass:"autocomplete-suggestion",attrs:{"to":'/' + product.url_key,"data-index":"0"}},[_c('img',{staticClass:"product-image",attrs:{"src":product.small_image.url,"alt":"Product","width":"40","height":"40"}}),_c('div',{staticClass:"search-name",domProps:{"innerHTML":_vm._s(_vm.matchEmphasize(product.name))}}),_c('span',{staticClass:"search-price"},[(
                    product.price_range.maximum_price.regular_price.value ==
                    product.price_range.maximum_price.final_price.value
                  )?_c('div',{staticClass:"product-price mb-0"},[_vm._v(" $"+_vm._s(product.price_range.maximum_price.regular_price.value.toFixed( 2 ))+" ")]):[(product.variants.length == 0)?_c('div',{staticClass:"product-price mb-0"},[_c('span',{staticClass:"new-price"},[_vm._v("$"+_vm._s(product.price_range.maximum_price.regular_price.value.toFixed( 2 )))]),_c('span',{staticClass:"old-price"},[_vm._v("$"+_vm._s(product.price_range.maximum_price.final_price.value.toFixed( 2 )))])]):_c('div',{staticClass:"product-price mb-0"},[_vm._v(" $"+_vm._s(product.price_range.maximum_price.regular_price.value.toFixed( 2 ))+" - $"+_vm._s(product.price_range.maximum_price.final_price.value.toFixed( 2 ))+" ")])]],2)])})],2):_vm._e(),(_vm.recommendedCategories && _vm.recommendedCategories.length > 0)?_c('div',[_c('h6',{staticClass:"mt-2"},[_vm._v("▾ Categorieën voor uw zoekopdracht:")]),_c('ul',{staticClass:"suggestion-searches"},_vm._l((_vm.recommendedCategories),function(cat,i){return _c('li',{key:i},[_c('router-link',{attrs:{"to":'/' + cat.url_path}},[_c('img',{staticClass:"d-inline",attrs:{"src":cat.image,"width":"20","alt":cat.name}}),_c('span',[_vm._v(" "+_vm._s(cat.name))])])],1)}),0)]):_vm._e(),(_vm.recommendedBlogs && _vm.recommendedBlogs.length > 0)?_c('div',[_c('h6',{staticClass:"mt-2"},[_vm._v("▾ "+_vm._s(_vm.$t("about_search"))+":")]),_c('div',{staticClass:"row"},_vm._l((_vm.recommendedBlogs),function(blog,idx){return _c('div',{key:'blog' + idx,staticClass:"col-md-6",on:{"click":function($event){return _vm.$router.push('/')}}},[_c('div',{staticClass:"box"},[_c('h6',[_vm._v(_vm._s(blog.title))]),_c('p',{domProps:{"innerHTML":_vm._s(blog.content)}})])])}),0)]):_vm._e(),_c('hr'),_c('h6',{staticClass:"mt-2"},[_vm._v("▾ Producten voor je zoekopdracht:")])]):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"search-toggle d-none",attrs:{"href":"#","role":"button"}},[_c('i',{staticClass:"icon-search"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_c('i',{staticClass:"fa-solid fa-magnifying-glass"})])}]

export { render, staticRenderFns }